import styled from "styled-components"

import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MButtonLegacy } from "src/ui/Button/MButtonLegacy"
import { MDialog } from "src/ui/Dialog/MDialog"
import CheckMark from "src/ui/icons/checkmark-green.svg"
import { spacing } from "src/ui/spacing"

export function PlateStoreSuccessDialog({ open }: { open: boolean }) {
  const { navigate } = useRouter()
  const { t, langKeys } = useTranslate()

  if (!open) {
    return null
  }

  return (
    <MDialog
      // As per the design, we're intentionally disabling being able to close
      // this dialog.
      onClose={() => {}}
      open={open}
      title={
        <DialogTitle>
          <CheckMark />
          {t(langKeys.mounting_plate_order_thanks)}
        </DialogTitle>
      }
      description={t(langKeys.mounting_plate_order_email_on_shipping)}
      hideClose
      showActionBar={false}
    >
      <Contents>
        <MButtonLegacy
          onClick={() => {
            navigate(Routes.Dashboard.location())
          }}
        >
          {t(langKeys.go_to_dashboard)}
        </MButtonLegacy>
      </Contents>
    </MDialog>
  )
}

const DialogTitle = styled.div`
  display: flex;
  gap: ${spacing.XS};
`
const Contents = styled.div`
  display: flex;
  justify-content: end;
`
