import { Value as PhoneNumberValue } from "react-phone-number-input"
import styled from "styled-components"

import { FormControl, InputLabel, Select, TextField } from "@material-ui/core"

import {
  countryData,
  getProvinceArray,
} from "src/components/Account/BillingPortal/countryData"
import {
  IShippingAddress,
  TShippingField,
} from "src/components/order/MountingPlateStore/MountingPlateOrderWizard/shippingAddressTypes"
import { TCountryDataCodes } from "src/data/countries/countryTypes"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { PhoneNumberInput } from "src/ui/PhoneNumberInput/PhoneNumberInput"
import { isValidCountry } from "src/ui/PhoneNumberInput/phoneNumberUtil"
import { spacing } from "src/ui/spacing"
import { Maybe } from "src/utils/tsUtil"

export function ShippingFields({
  shippingAddress,
  setField,
  phoneError,
}: {
  shippingAddress: Maybe<IShippingAddress>
  setField: (p: TShippingField) => void
  phoneError: boolean | undefined
}) {
  const { t } = useTranslate()

  const countryCode = isValidCountry(shippingAddress?.country)
    ? shippingAddress?.country
    : undefined

  const provinces = getProvinceArray(shippingAddress?.country)

  function printOptional() {
    return `(${t(langKeys.input_optional)?.toLocaleLowerCase()})`
  }

  function handlePhoneChange(phone: PhoneNumberValue | undefined) {
    setField({ phone })
  }

  return (
    <Grid>
      <Row>
        <TextField
          label={t(langKeys.first_name)}
          fullWidth
          required
          autoComplete="given-name"
          value={shippingAddress?.first_name ?? ""}
          onChange={(e) => setField({ first_name: e.target.value })}
        />
        <TextField
          label={t(langKeys.last_name)}
          autoComplete="family-name"
          fullWidth
          required
          value={shippingAddress?.last_name ?? ""}
          onChange={(e) => setField({ last_name: e.target.value })}
        />
      </Row>

      <TextField
        label={`${t(langKeys.address_company)} ${printOptional()}`}
        autoComplete="organization"
        fullWidth
        value={shippingAddress?.company ?? ""}
        onChange={(e) => setField({ company: e.target.value })}
      />

      <TextField
        label={t(langKeys.address_form_street_name_1)}
        autoComplete="address-line-1"
        fullWidth
        required
        value={shippingAddress?.line1 ?? ""}
        onChange={(e) => setField({ line1: e.target.value })}
      />

      <TextField
        label={`${t(langKeys.address_form_street_name_2)}`}
        autoComplete="address-line-2"
        fullWidth
        value={shippingAddress?.line2 ?? ""}
        onChange={(e) => setField({ line2: e.target.value })}
      />

      <Row>
        <TextField
          label={`${t(langKeys.address_form_city)}`}
          autoComplete="address-level2"
          fullWidth
          required
          value={shippingAddress?.city ?? ""}
          onChange={(e) => setField({ city: e.target.value })}
        />
        <TextField
          label={t(langKeys.address_form_postcode)}
          autoComplete="postal-code"
          fullWidth
          required
          value={shippingAddress?.zip ?? ""}
          onChange={(e) => setField({ zip: e.target.value })}
        />
      </Row>

      <Row>
        <FormControl variant="outlined" fullWidth>
          <InputLabel
            htmlFor="country"
            required
            style={{ background: "white" }}
          >
            {t(langKeys.address_form_country)}
          </InputLabel>
          <Select
            autoComplete="country"
            native
            value={shippingAddress?.country ?? ""}
            required
            inputProps={{ id: "country" }}
            onChange={(e) => {
              setField({ country: e.target.value as TCountryDataCodes })
            }}
          >
            <option value="" disabled hidden />
            {countryData.map((country) => (
              <option value={country.code} key={country.code}>
                {country.label}
              </option>
            ))}
          </Select>
        </FormControl>

        {provinces.length > 0 && (
          <>
            <FormControl variant="outlined" fullWidth>
              <InputLabel
                htmlFor="address-level1"
                style={{ background: "white" }}
                required
              >
                {t(langKeys.address_form_state)}
              </InputLabel>
              <Select
                autoComplete="address-level1"
                native
                value={
                  shippingAddress?.state_code || shippingAddress?.state || ""
                }
                required
                inputProps={{
                  id: "address-level1",
                }}
                onChange={(e) => {
                  setField({
                    state_code: e.target.value as string,
                  })
                }}
              >
                <option value="" disabled hidden></option>
                {provinces.map((province) => (
                  <option value={province.code} key={province.code}>
                    {province.label}
                  </option>
                ))}
              </Select>
            </FormControl>
          </>
        )}
      </Row>

      <PhoneNumberInput
        required
        defaultCountry={countryCode}
        value={shippingAddress?.phone ?? ""}
        error={phoneError}
        onChange={(n) => handlePhoneChange(n)}
        countryCallingCodeEditable
      />
    </Grid>
  )
}

const Grid = styled.div`
  display: grid;
  row-gap: 1rem;
`

const Row = styled.div`
  display: flex;

  > * {
    margin-left: ${spacing.XS2};
    margin-right: ${spacing.XS2};
  }

  *:first-child {
    margin-left: 0;
  }

  *:last-child {
    margin-right: 0;
  }
`
